import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import ChatApp from './pages/ChatApp.jsx';
import Home from './pages/Home.jsx';

const App = () => (
  <Router>
     <Route exact path="/" component={Home} />
     <Route exact path="/app" component={ChatApp} />
  </Router>
);

export default App;
