import React from 'react';
import styled from 'styled-components'


const Container = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > .message {
    background-color: ${props => props.theme.primaryColor};
    color: white;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.37);
    padding: 15px;
    min-width: 5%;
    font-size: 12px;
    @media (min-width: 700px) {
      font-size: 16px;
      padding: 25px;
    }
  }

  & > .time {
    color: #b1afaf;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 10px;
    padding-top: 5px;
    @media (min-width: 700px) {
      font-size: 12px;
    }
  }
  
`;

const UserMessage = ({message, time}) => (
  <Container>
    <div className="message">{message}</div>
    <div className="time">{time}</div>
  </Container>
)

export default UserMessage;