import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Theme from './../Theme';
import Header from './../components/Header.component.jsx';
import Conversation from './../components/Conversation/index.jsx';
import BotMessage from './../components/Conversation/BotMessage.jsx';
import UserMessage from './../components/Conversation/UserMessage.jsx';
import Footer from './../components/Footer.component.jsx';

const DUMMY_MESSAGES = [
  {
    from: "ME",
    message: "Hi!!",
    time: "A minute ago",
  },
  {
    from: "BOT",
    message: {
      texts: [
        "Nice to meet you. Let me help with your queries",
        "If you are confused to begin with, you can select any of these suggested topic below."
      ],
      actionButtons: [
        "Configuration Related",
        "XGST Related",
        "Report Related",
        "VAT RELATED",
        "More..."
      ]
    }
  },
  {
    from: "Me",
    message: "Not sure though!!",
    time: "Just now",
  },
  {
    from: "Me",
    message: "How to install OS?",
    time: "Just now",
  },
  {
    from: "BOT",
    message: {
      texts: [
        "Below are the steps",
      ],
      steps: [
        {
          type: "VIDEO",
          url: "https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
        },
        {
          type: "TEXT",
          header: "Step1",
          body: "Access VAT Heads - Create screen from Xelerate > Parameter Home > VAT Heads > Create. This displays the VAT Heads - Create screen as shown.",
          buttonText: "Read More.."
        },
        {
          type: "TEXT",
          header: "Step2",
          body: "Access VAT Heads - Create screen from Xelerate > Parameter Home > VAT Heads > Create.This displays the VAT Heads - Create screen as shown.",
          buttonText: "Read More.."
        },
        {
          type: "TEXT",
          header: "Step3",
          body: "Access VAT Heads - Create screen from Xelerate > Parameter Home > VAT Heads > Create.This displays the VAT Heads - Create screen as shown.",
          buttonText: "Read More.."
        },
        {
          type: "TEXT",
          header: "Step3",
          body: "Access VAT Heads - Create screen from Xelerate > Parameter Home > VAT Heads > Create.This displays the VAT Heads - Create screen as shown.",
          buttonText: "Read More.."
        }
        
      ]
    }
  }
];

const Container = styled.div`
  background-image: ${props => props.theme.bodyBackgroundColor};
`;

const BotApp = () => (
  <ThemeProvider theme={Theme}>
    <Container>
      <Header>
      </Header>
      <Conversation>
        {
          DUMMY_MESSAGES.map(({from, message, time}, id) => {
            return from === "BOT"
              ?
                <BotMessage
                  key={id}
                  message={message}
                >
                </BotMessage>
              :
                <UserMessage
                  key={id}
                  message={message}
                  time={time}
                  >
                </UserMessage>
            }
          )
        }
      </Conversation>
      <Footer></Footer>
    </Container>
  </ThemeProvider>
);


export default BotApp;
