import React from 'react';
import styled from 'styled-components'
import FontAwesome from 'react-fontawesome';
import Modal from 'react-modal';
import Carousel from 'nuka-carousel';

import bot from './../../images/bot.png';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  & > .icon-container {
    width: 15%;
    min-width: 15%;
    max-width: 15%;
    
    @media (min-width: 700px) {
      width: 6%;
      min-width: 6%;
      max-width: 6%;
    }

    & > .icon {
      width: ${props => props.welcome? '100%': '80%'};
      max-width: ${props => props.welcome? '100%': '80%'};
      min-width: ${props => props.welcome? '100%': '80%'};
      & > img {
        width: 100%;
      }
    }
  }

  & > .content {
    padding-left: 10px;
    display: inline-block;
    width: 85%;

    @media (min-width: 700px) {
      width: 94%;
    }

    & > .welcome {
      border-top-left-radius: 30px !important;
    }
    & > .text-message {
      background-color: ${props => props.theme.botMessage};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      margin-bottom: 10px;
      border-bottom-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 15px;
      max-width: 100%;
      width: max-content;
      font-size: 12px;
      @media (min-width: 700px) {
        font-size: 16px;
        max-width: 70%;
        padding: 25px;
      }

      & > h2 {
        margin-top: 0px !important;
        font-size: 16px !important;

        @media (min-width: 700px) {
          font-size: 25px !important;
        }
      }
    }
    & > .action-buttons {
      display: flex;
      flex-direction: row;
      padding-top: 10px
      overflow-x: scroll;
      @media (min-width: 700px) {
        overflow-x: visible;
      }
      & > div {
        color: ${props => props.theme.primaryColor};
        border: solid 1px ${props => props.theme.primaryColor};
        padding: 10px;
        border-radius: 38px;
        cursor: pointer;
        margin-right: 15px;
        white-space: nowrap;
        font-size: 12px;
        @media (min-width: 700px) {
          font-size: 16px;
          overflow-x: visible;
          padding: 15px;
        }
      }
    }
    & > .steps {
      & .step {
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 20px 10px;
        margin-left: 3px;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          & > * {
            text-align: center;
          }
          & > h3 {
            font-weight: 500;
            margin: 0px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
          }
          & > h4 {
            line-height: 20px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 400;
            margin: 10px 0px
          }
          & > .button {
            border-radius: 2px;
            border: 1px solid #70006a;
            background-color: #ffffff;
            color: #8d0085;
            font-weight: 400;
            padding: 10px 20px;
            width: max-content;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
  }
`

const VideoPreviewContainer = styled.div`
  width: 100%;
  height: fill-available;
  position: absolute;
  overflow: hidden;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & > div.icon-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > div.icon {
      padding: 10px 15px;
      border-radius: 50%;
      background-color: white;
    }
  }
`

const VideoPlayerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > video {
    width: 100vw;
    @media (min-width: 700px) {
      width: 70vw;
    }
  }

  & > .close-button {
    z-index: 400;
    color: white;
    font-size: 24px;
    cursor: pointer;
    width: 100vw;
    text-align: right;
    padding-bottom: 10px;
    @media (min-width: 700px) {
      padding-right: 0px !important;
      width: 70vw;
    }
  }
`

const WELCOME_TEXT_MESSAGES = [
  "<h2>Welcome</h2><div>I will do my best to help you with all your technical support related queries. But I am still a bot , so please be patient with me. I will make mistakes.</div>",
  "If you are confused to begin with, you can select any of these suggested topic below."
]
const WELCOME_BUTTONS = [
  "Configuration Related",
  "XGST Related",
  "Report Related",
  "VAT Related",
  "More..."
]

class VideoPreview extends React.Component {
  

  constructor(props) {
    super(props);
    this.state = {
      showVideoPlayerPopup: false
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.customStyles = {
      content : {
        background: "tranparent",
      }
    };
  }

  togglePopup() {
    this.setState({
      showVideoPlayerPopup: !this.state.showVideoPlayerPopup
    });
  }

  closePopup() {
    this.setState({
      showVideoPlayerPopup: false,
    });
  }

  render () {
    
    const { url } = this.props
    const { showVideoPlayerPopup } = this.state;

    return (
      <VideoPreviewContainer>
        <video controls={false}>
          <source src={url} type="video/mp4" />
        </video>
        <div className="icon-container" onClick={this.togglePopup}>
          <div className="icon">
            <FontAwesome name="play" />
          </div>
        </div>
        <Modal
          isOpen={showVideoPlayerPopup}
          style={this.customStyles}
          >
          <VideoPlayerContainer>
            <FontAwesome className="close-button" name="times" onClick={this.togglePopup}/>
            <video controls autoPlay="true">
              <source src={url} type="video/mp4" />
            </video>
          </VideoPlayerContainer>
        </Modal>
      </VideoPreviewContainer>
    );
  }
}

const BotMessage = ({welcome, message}) => {
  const isMobile = window.innerWidth < 700;
  const showItems = isMobile ? 1.2 : 4;
  return (
  <Container welcome={welcome}>
    <div className="icon-container">
  	<div className="icon">
      <img src={bot} alt="bot"/>
    </div>
    </div>
    <div className="content">
      {
        welcome && WELCOME_TEXT_MESSAGES.map((message, id) => (
          <div key={id}
            className={id === 0? 'welcome text-message': 'text-message'}
            dangerouslySetInnerHTML={{__html: message}}>
          </div>)
        )
      }
      {
        welcome && 
        <div className="action-buttons">
          {
            WELCOME_BUTTONS.map((buttonName, id) => (
              <div key={id}>{buttonName}</div>
            ))
          }
        </div>
      }
      {
        message && message.texts
          && message.texts.map((textMessage, id) => (
            <div key={id}
              className="text-message"
              >
              {textMessage}
            </div>)
          )
      }
      {
        message && message.steps
          && <div className="steps">
          <Carousel
            slidesToShow={showItems}
            renderCenterLeftControls={({ previousSlide }) => (
              <button onClick={previousSlide}>
                <FontAwesome name="chevron-left" />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button onClick={nextSlide}>
                <FontAwesome name="chevron-right" />
              </button>
            )}>
            {
              message.steps.map((step, id) => {
                if(step.type === "VIDEO") {
                  return (
                    <VideoPreview url={step.url} key={id}>
                    </VideoPreview>
                  )
                }
                return (
                  <div className="step" key={id}>
                    <div>
                      <h3>{step.header}</h3>
                      <h4>{step.body}</h4>
                      <div className="button">{step.buttonText}</div>
                    </div>
                  </div>
                )
              })
            }
          </Carousel>
        </div>
      }
    </div>
  </Container>
  ); 
}

export default BotMessage;