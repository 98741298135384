import React from 'react';
import styled, { withTheme } from 'styled-components'
import FontAwesome from 'react-fontawesome';
import Collapsible from 'react-collapsible';

import logo from './../images/logo_rio.png';

const Container = styled.div`
  width: 100vw;
  height: 12vh;
  box-shadow: 0 2px 7px 1px rgba(166, 158, 158, 0.5);
  background-color: ${props => props.theme.headerBackgroundColor};

  & > div.header-container {
    height: 100%;
    padding: 10px;
    padding-left: ${props => props.theme.horizontalMarginMobile};
    padding-right: ${props => props.theme.horizontalMarginMobile};
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 700px) {
      padding-left: ${props => props.theme.horizontalMarginDesktop};
      padding-right: ${props => props.theme.horizontalMarginDesktop};
    }

    & > div.image-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: start;
      align-items: center;

      & > img {
        height: 45%;
        object-fit: contain;
      }

      & > div {
        color: ${props => props.theme.primaryColor};;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        @media (min-width: 700px) {
          font-size: 32px;
        }
      }
    }
  }
`

const HelpButton = styled.button`
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.85);
  background-color: #ffffff;
  padding: 5px 6px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 700px) {
    border-radius: 13px;
    padding: 10px 30px;
  }
  & > .fa {
    font-size: 14px;
    color: rgba(31, 31, 31, 0.85);
    
    @media (min-width: 700px) {
      margin-right: 5px;
      font-size: 20px;
    }
  }
  & > .text {
    display: none;
    color: rgba(31, 31, 31, 0.85);

    @media (min-width: 700px) {
      display: block;
    }
  }
`
const Sidebar = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  transform: translate(100%);
  transition: transform 0.7s;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 0 2px 9px rgba(73, 73, 73, 0.5);
  padding: 0px !important;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;

  @media (min-width: 700px) {
    width: 45vw;
  }

  & > .header {
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    @media (min-width: 700px) {
      font-size: 25px;
      font-weight: bold;
    }
    & > .icon {
      color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  
  &.show {
    transform: translate(0%);
  }

  & > div {
    padding: 15px;
    box-shadow: 0 0px 0.5px rgba(73,73,73,0.5);
    @media (min-width: 700px) {
      padding: 30px;
    }
  }

  & > .Collapsible .Collapsible__contentInner > div {
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0px 0px;
    color: #808080;

    @media (min-width: 700px) {
      font-size: 16px;
      padding: 20px 0px 0px;
    }
  }
`

const TriggerContainer = styled.div`
  display: flex;
  flex-container: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & > .label-name {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 700;
    font-weight: 500;
    @media (min-width: 700px) {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const Trigger = ({name, open}) => (
  <TriggerContainer>
    <div className="label-name">{name}</div>
    <FontAwesome name='chevron-down'/>
  </TriggerContainer>
)

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  render() {
    const {
      sidebarOpen
    } = this.state;

    return (<Container>
      <div className="header-container">
        <div className="image-container">
          <img src={logo} alt="logo" />
        </div>
        <HelpButton onClick={() => this.onSetSidebarOpen(true)}>
          <FontAwesome name='question-circle' />
          <div className="text">Help</div>
        </HelpButton>
      </div>
      <Sidebar className={sidebarOpen? 'show': ''}>
        <div className="header">
          <FontAwesome className="icon" name='chevron-right' onClick={() => this.onSetSidebarOpen(false)}/>
          <div>User Manual</div>
        </div>
        <Collapsible trigger={<Trigger name="Base Configuration"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
        <Collapsible trigger={<Trigger name="Defining VAT Heads"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
        <Collapsible trigger={<Trigger name="Defining Currency Codes"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
        <Collapsible trigger={<Trigger name="Defining Currency Exchange Rates"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
        <Collapsible trigger={<Trigger name="Mapping of Specific Day as a Holiday or Special Day"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
        <Collapsible trigger={<Trigger name="Uploading Data in Xelerate"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
        <Collapsible trigger={<Trigger name="Defining Source System Level Functional Parameters"></Trigger>}>
          <div>Introduction to Basic Configuration</div>
          <div>Fundamentals of Basic Configuration</div>
        </Collapsible>
      </Sidebar>
    </Container>
    );
  }
}

export default withTheme(Header);