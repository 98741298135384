import React from 'react';
import styled from 'styled-components'
import FontAwesome from 'react-fontawesome';
import { Link } from "react-router-dom";

import bank from './../images/bankAbc.png';
import cognizant from './../images/cognizant.png';
import commerce from './../images/commerce.png';
import bot from './../images/bot.png';
import chatBubbleRight from './../images/chat-bubble-right.png';
import chatBubbleLeft from './../images/chat-bubble-left.png';
import mobileFooter from './../images/mobile-footer.svg';
import desktopFooter from './../images/desktop-footer.svg';
import headerImg from './../images/powered_by_suntec.png';

const HeaderContainer = styled.div`
  width: 100%;
  height: 15vh;
  background-color: white;
  @media (min-width: 700px) {
	  padding-left: 100px;
	  padding-right: 100px;
  }

  & > div.header-container {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media (min-width: 700px) {
    	justify-content: flex-end;
    }

    & > div.image-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      align-items: center;
      width: 70%;
      @media (min-width: 700px) {
      	justify-content: flex-end;
	  }

      & > img {
      	width: 100%;
        object-fit: contain;
        @media (min-width: 700px) {
        	height: 20%;
        	width: unset;
	    }
      }

      & > div {
      	color: #b144a3;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        @media (min-width: 700px) {
          font-size: 32px;
        }
      }
    }
  }
`

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`

const MainBody = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 10px;
  padding-right: 10px;

	@media (min-width: 700px) {
	  padding-left: 100px;
	  padding-right: 100px;
  }

	& > .upper-content {
		display: flex;
		flex-direction: row;
		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (min-width: 700px) {
				align-items: flex-start;
			}

			& > div.heading {
				color: rgba(0, 0, 0, 0.85);
				font-size: 40px;
				font-weight: 600;
				text-align: center;
				@media (min-width: 700px) {
					text-align: left;
				}
			}

			& > div.subheading {
				color: rgba(0, 0, 0, 0.85);
				font-size: 18px;
				font-weight: 500;
				letter-spacing: 0.2px;
				line-height: 27px;
				text-align: center;
				@media (min-width: 700px) {
					text-align: left;
				}
			}

			& > div.seperator {
				width: 121px;
				height: 1px;
				border: 3px solid #8d309b;
				margin-top: 12px;
			}	
		}
	}

	& > div.lower-content {
		display: flex;
		flex-direction: column;
		
		@media (min-width: 700px) {
			max-height: 30vh;
			flex-direction: row;
		}

		& > div {
			width: 100%;
			@media (min-width: 700px) {
				width: 50%;
				min-width: 50%;
			}
		}

		& > .left {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (min-width: 700px) {
				align-items: start;
    	}

			& > div.body {
				color: rgba(0, 0, 0, 0.85);
				font-size: 18px;
				font-weight: 300;
				letter-spacing: 0.2px;
				line-height: 30px;
				margin-top: 50px;
				text-align: center;
				@media (min-width: 700px) {
					text-align: left;
				}
			}
			& > a > div.button {
				font-size: 18px;
				font-weight: 500;
				letter-spacing: 0.2px;
				color: #ffffff;
				box-shadow: 0 2px 10px #3426a4;
				border-radius: 5px;
				background-color: #98369d;
				margin-top: 50px;
				padding: 10px 20px;
				cursor: pointer;
			}
			& > div.services {
				margin-top: 30px;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				& > div.hide-on-mobile {
					display: none;
					@media (min-width: 700px) {
						display: block;
					}
				}

				& > div {
					color: rgba(0, 0, 0, 0.5);
					font-size: 16px;
					font-weight: 300;
					letter-spacing: 0.18px;
					line-height: 27px;
				}
			}
		}

		& > .right {
			max-height: 50vh;
			padding-top: 50px;
			@media (min-width: 700px) {
				max-height: unset !important;
				padding-left: 100px;
				padding-top: 0px;
			}
			& > div {
				display: flex;
				flex-direction: row;
				padding-bottom: 50px;

				& > div.large-pane {
					width: 55%;
				}

				& > div.small-pane {
					width: 50%;
				}

				& > div.chat-box {
					box-shadow: 0 2px 10px rgba(180, 176, 180, 0.64);
					padding: 15px;
					background-color: #ffffff;
					position: relative;
					margin-bottom: 30px;

					& > .dummy-line {
						background-color: #efebeb;
						height: 20px;
						width: 100%;
						margin-bottom: 5px;
					}
				}

				& > div.left-chat-box {
					border-top-left-radius: 15px;
					&::after {
					  position: absolute;
					  bottom: -55px;
					  left: 70%;
					  height: 55px;
					  content: url(${chatBubbleLeft});
					}
				}

				& > div.right-chat-box {
					border-top-right-radius: 15px;

					&::after {
					    position: absolute;
					    bottom: -55px;
					    left: 25%;
					    height: 55px;
					    content: url(${chatBubbleRight});
					}
				}

				& > .button-container {
					position: relative;

					& > div.round-button {
						position: absolute;
						bottom: 0px;
						width: 30px;
						height: 30px;
						box-shadow: 0 2px 10px rgba(145, 50, 156, 0.69);
						background-color: #ffffff;
						border-radius: 100%;
						padding: 5px;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						color: #8d309b;
						font-size: 16px;

						&.left-align {
							left: 10px;
						}

						&.right-align {
							right: 10px;
						}

						& > img {
							width: 100%;
							height: 100%;
						}				
					}
				}
			}
		}
	}
`

const Footer = styled.div`
	background-image: url(${mobileFooter});
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 80%;
	@media (min-width: 700px) {
		height: 60%;
		background-image: url(${desktopFooter});
	}
`;

const Home = () => (
	<MainContainer>
		<HeaderContainer>
			<div className="header-container">
	      <div className="image-container">
	        <img src={headerImg} alt="logo" />
	      </div>
	    </div>
		</HeaderContainer>
		<MainBody>
			<div className="upper-content">
				<div>
					<div className="heading">Meet Sunny</div>
					<div className="subheading">A chatbot to assist you for all technical queries</div>
					<div className="seperator"></div>
				</div>
				<div>
				</div>
			</div>
			<div className="lower-content">
				<div className="left">
					<div className="body">Sunny, a four-time winner of the Loebner Prize Turing Test, is the world's best chatbot. As featured in the New York Times, Wall Street Journal, BBC, Guardian, Wired, and more.
					</div>
					<Link to="/app"><div className="button">Ask Your Queries</div></Link>
					<div className="services">
						<div className="hide-on-mobile">Currently Servicing</div>
						<img src={bank} alt="Bank logo" />
						<img src={cognizant} alt="Cognizant" />
						<img src={commerce} alt="commerce" />
					</div>
				</div>
				<div className="right">
					<div>
						<div className="large-pane chat-box left-chat-box">
							<div className="dummy-line"></div>
							<div className="dummy-line"></div>
							<div className="dummy-line"></div>
						</div>
						<div className="button-container small-pane">
							<div className="round-button left-align">
								<img src={bot} alt="Bot"/>
							</div>
						</div>
					</div>
					<div>
						<div className="button-container small-pane">
							<div className="round-button right-align">
								<FontAwesome name="user" />
							</div>
						</div>
						<div className="large-pane chat-box right-chat-box">
							<div className="dummy-line"></div>
							<div className="dummy-line"></div>
							<div className="dummy-line"></div>
						</div>
					</div>
					<div>
						<div className="large-pane chat-box left-chat-box">
							<div className="dummy-line"></div>
							<div className="dummy-line"></div>
							<div className="dummy-line"></div>
						</div>
						<div className="button-container small-pane">
							<div className="round-button left-align">
								<img src={bot} alt="Bot"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
			</div>
		</MainBody>
		<Footer>
		</Footer>
	</MainContainer>
)

export default Home;