import React from 'react';
import styled from 'styled-components'

import BotMessage from './BotMessage.jsx';

const Container = styled.div`
  width: 100vw;
  height: 73vh;
  overflow-y: scroll;

  & > div {
    height: 100%;
    padding-left: ${props => props.theme.horizontalMarginMobile};
    padding-right: ${props => props.theme.horizontalMarginMobile};
    padding-top: 100px;
    @media (min-width: 700px) {
      padding-left: ${props => props.theme.horizontalMarginDesktop};
      padding-right: ${props => props.theme.horizontalMarginDesktop};
    }
  }
`


export default class Conversation extends React.Component {

  componentDidMount() {
    setTimeout(
      function() {
        this.el.scrollTop = this.el.scrollHeight;
      }
      .bind(this),
      200
    );
  }

  render() {
    const {
      children
    } = this.props;

    return (<Container ref={el => { this.el = el; }}>
      <div>
        <BotMessage welcome>
          </BotMessage>
        {children}
      </div>
    </Container>);
  }

}