import React from 'react';
import styled from 'styled-components'
import FontAwesome from 'react-fontawesome';
import { Typeahead } from 'react-bootstrap-typeahead';

const Container = styled.div`
	height: 15vh;
	width: 100vw;

	& > div {
  	position: relative;
  	padding-left: ${props => props.theme.horizontalMarginMobile};
    padding-right: ${props => props.theme.horizontalMarginMobile};
    @media (min-width: 700px) {
      padding-left: ${props => props.theme.horizontalMarginDesktop};
      padding-right: ${props => props.theme.horizontalMarginDesktop};
    }
  	& input {
      box-shadow: 0 2px 10px 2px rgba(161, 161, 161, 0.5);
  		border-radius: 7px;
  		background-color: #ffffff;
  		border: none;
  		width: 100%;
  		outline: none;
  		padding: 15px !important;
    	box-sizing: border-box;
      height: unset !important;
      z-index: 2 !important;
  	}

    & ul.dropdown-menu {
      box-shadow: 0 -3px 7px 3px rgba(0,0,0,0.21);
      border-bottom: 0px;
      border-radius: 40px 40px 0 0;
      background-color: #ffffff;
      padding: 20px 10px;
      z-index: 1 !important;

      & > li {
        padding: 10px 0px;
        & > a {
          padding-left: 20px;
        }
      }
    }

  	& > .fa {
  		color: #b1afaf;
  		position: absolute;
  		right: 10%;
        top: 33%;
    	cursor: pointer;
        z-index:2;
  	}
}
`
const OPTIONS = [
  {
    id: 0,
    name: "What is that?",
  },
  {
    id: 1,
    name: "How to do it?",
  },
  {
    id: 2,
    name: "Normally maybe?"
  }
]

const OptionRenderer = (option, props, index) => {
  console.log(option, props.text, index);
  return [
    <div key="name" search={props.text}>
      <FontAwesome name='search' />&nbsp;&nbsp;
      {option.name}
    </div>,
  ]
}

const Footer = () => (
	<Container>
		<div>
      <Typeahead
        labelKey="name"
        flip={true}
        options={OPTIONS}
        placeholder="Type your queries here..."
        renderMenuItemChildren={OptionRenderer}
        />
      <FontAwesome name='paper-plane' />
		</div>
	</Container>
);

export default Footer;